.downloadButton {
  text-decoration: none;
  color: #f26826;
  font-size: 20px;
  padding: 14px;
  padding-top: 0px;
  display: inline-block;
}
.downloadButton:hover {
  color: #f26826;
}
.exportDataText {
  color: white;
  font-weight: 700;
  font-size: 18px;
  padding: 14px;
  display: block;
}
