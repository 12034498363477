.winlossButtons {
  padding: 7px;
  border-radius: 12%;
  color: white;
  margin-left: 3px !important;
  cursor: pointer;
}
.videoModal {
  left: -15%;
  transform: translate3d(0px, 0px, 0px);
  top: -10%;
}
