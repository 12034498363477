.outer {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.root {
  margin: 10px;
  padding: 25px;
  border-radius: 8px;
  width: 224px;
  background: #232323;
  text-align: center;
}
.tileTitle {
  font-size: 14px;
  padding: 5px 0px;
}
.content {
  font-size: 10px;
  color: #b4b4b4;
}
.value {
  font-size: 10px;
}
.teamIcon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.icons {
  padding-right: 5px;
  height: 20px;
  width: 20px;
}
