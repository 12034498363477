.scrollWrapper {
  // height: 70vh;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollWrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollWrapper {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.leftRectangle {
  padding: 0px 20px;
  min-height: 300px;
  border: 1px solid #707070;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
}

.leftInventoryRectangle {
  padding: 25px 20px;
  // min-height: 200px;
  border: 1px solid #707070;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
}

.inventoryItemsWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.gamesListItemsWrapper {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.inventoryItem {
  box-sizing: border-box;
  width: 23%;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

@media (max-width: 678px) {
  .inventoryItem {
    width: 30%;
  }
}

.inventoryItem .imgWrapper {
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 12px;
  display: inline-block;
}

.inventoryItem img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}


.inventoryItem .title {
  color: #fff;
  word-wrap: break-word;
}

.centerInnerRows {
  justify-content: center;
}

.stats {
  color: whitesmoke !important;
  text-align: left;
}

.statsHead {
  color: #767676;
  margin: 0;
  text-align: left;
}

.successText {
  color: #00ff00;
}

.dangerText {
  color: #f22626;
}

.stateItem {
  width: 120px;
  padding: 10px 0px;
}

.aboutBox {
  width: 100%;
  word-break: break-all;
}

.rightRectangle {
  border: 1px solid #707070;
  border-radius: 8px;
  padding: 0px 6px;
  word-break: break-all;
  margin-top: 25px;
  //   height: 310px !important;
}

.franchAboutSec {
  overflow-y: auto;
  height: 260px;
  color: whitesmoke;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.franchAboutSec::-webkit-scrollbar {
  width: 3px;
  background-color: #1a1a1a;
}

/* Hide scrollbar for IE, Edge add Firefox */
.franchAboutSec::-webkit-scrollbar-thumb {
  background: #4a4a4a;
  border-radius: 8px;
}

.profileGridWrapper {
  max-height: 500px;
  overflow: scroll;
}