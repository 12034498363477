  .gamesDiv{
    margin-right:5;
    width: 280px;
    height: 100;
    background-color: #000000;
    border: 1px solid #F26826;
    border-radius: 16px;
    opacity: 1;
    margin: 3px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    
}