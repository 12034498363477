.topSection {
  height: 260px;
  width: 100%;
  background: black;
  border-radius: 8px;
  position: relative;
  border-bottom: 2px solid #f26826;
}
.tournamentProfileImg {
  position: absolute;
  bottom: 2%;
  display: flex;
  align-items: center;
}
.backChevron {
  top: 2%;
  left: 2%;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
}
.gameName {
  padding-left: 4%;
  font-size: 30px;
  color: white;
  height: 90px;
  width: 60%;
  background: linear-gradient(
    90deg,
    rgba(19, 18, 18, 0.781) 39%,
    rgba(0, 212, 255, 0) 97%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  bottom: 10%;
  font-style: bold;
}
.centerBar {
  padding-top: 20px;
  padding-bottom: 20px;
}
.tournamentName {
  color: white;
  font-size: 30px !important;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
}
.miniDetailsBar {
  padding: 15px;
  border: 1px solid #707070;
  border-radius: 8px;
}
.bottomBar {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 35px;
  padding-right: 35px;
  border: 1px solid #707070;
  border-radius: 8px;
  color: white;
}
.timetext {
  color: #f26826;
}
.winningBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
