.outer {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.root {
  margin: 10px;
  padding: 25px;
  border-radius: 8px;
  width: 290px;
  background: #232323;
  text-align: center;
}
.winBorder {
  border: 2px solid #87ff93;
}
.lossBorder {
  border: 2px solid #ff2e2e;
}
.tileTitle {
  font-size: 28px;
  padding: 5% 0px;
}
.content {
  font-size: 14px;
  color: #767676;
}
.value {
  font-size: 10px;
}
.teamIcon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.icons {
  padding-right: 5px;
  height: 20px;
  width: 20px;
}
