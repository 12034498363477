.inventory {
  box-sizing: border-box;
  padding: 12px;
  color: #fff;
}
.intventoryItem {
  padding: 10px;
  min-height: 180px;
}
.intventoryItem .wrapper {
  background-color: #373737;
  border-radius: 10px;
  height: 100%;
}
.intventoryItem .imageWrapper {
  padding: 10px;
  overflow: hidden;
  height: 100%;
}
.intventoryItem .imageWrapper img {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: contain;
  border: 1px solid gray;
}

.intventoryItem .contentWrapper {
  padding: 10px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.intventoryItem .contentWrapper h4 {
  text-align: center;
  font-size: 1.1rem;
}
.intventoryItem .contentWrapper div {
  text-align: center;
}
