.mainDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40lvh;
    width: 40lvw;
    position: absolute;
    top: 30%;
    left: 30%;
    color: #fff;
     background-color: black;
    flex-direction: column;
    opacity: 0.8;
    font-weight: 700;
    border-radius: 20px;
}

.btnSubmit{
    background-color: #fff;
}

.btnSubmit:hover{
    background-color: #fff;
}