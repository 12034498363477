.userProfileBackground {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  border-radius: 8px;
  position: relative;
}
.profileImgUpload {
  position: absolute;
  right: 3%;
  bottom: -3%;
}
.bgImgUpload {
  position: absolute;
  right: 2%;
  top: 4%;
}
.userprofileImg {
  background-size: cover;
  background-repeat: no-repeat;
  width: 135px;
  height: 135px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  position: relative;
  margin: 0px 10px 0px 10px;
}
.bgImg {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  position: relative;
  position: absolute;
  cursor: pointer;
}
.user-about-section {
  width: 100% !important;
  margin-top: 5rem !important;
}
.match-card-right-section {
  width: 70% !important;
}
