.users {
  display: flex;
  align-items: center;
}
.userName {
  padding-left: 6%;
  font-size: 14px;
  color: whitesmoke;
}
.convPicOut {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
}
.convPic {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.offline {
  background-color: #c18e59;
  position: absolute;
  border-radius: 24px;
  height: 11px;
  width: 11px;
  right: 2%;
  bottom: 2%;
}
.online {
  background-color: #77f226;
  position: absolute;
  border-radius: 24px;
  height: 11px;
  width: 11px;
  right: 2%;
  bottom: 2%;
}
