@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Work+Sans&display=swap');
@import url("https: //cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css");
@import url("https: //maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css");

.pupup-modal-wrapper{
    overflow-y: auto;
}

.popup-dialog {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #1A1A1A;
    border: 1px solid #FFFFFF4D;
    padding: 50px 0px;
    margin: 6% auto;
}

.popup-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.popup-title {
    margin: 0px !important;
    color: #DFDFDF;
    font-size: 20px !important;
    line-height: 21px !important;
}

.popup-subtitle {
    font-size: 20px !important;
    line-height: 20px !important;
    color: #A1A1A1;
}

.popup-sub-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.popup-sub-text .popup-title {
    margin: 0px !important;
    letter-spacing: 0px;
    color: #DFDFDF;
    font-size: 16px !important;
    line-height: 21px !important;
    padding-right: 20px;
}

.popup-sub-text .popup-subtitle {
    font-size: 18px !important;
    line-height: 15px !important;
    letter-spacing: 0px;
    color: #A1A1A1;
}

.popup-body {
    padding: 40px 0px;
}

.container {
    width: 100%;
    padding: 0px;
}

.nav-tabs {
    border: none !important;
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: center;
    align-items: center;
}

.nav-tabs .active a {
    color: #F26826 !important;
    cursor: pointer !important;
    background-color: transparent !important;
    font-size: 16px !important;
    line-height: 21px !important;
    border-bottom: 1px solid #F26826 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    font-weight: normal !important;
    margin: 0px !important;
    padding: 15px 40px !important;
}

.nav-tabs li a {
    color: #FFFFFF !important;
    cursor: pointer !important;
    background-color: transparent !important;
    font-size: 16px;
    line-height: 21px;
    border: none !important;
    font-weight: normal !important;
    margin: 0px !important;
    padding: 15px 40px !important;
}

.tab-content {
    padding: 40px 0px 0px 0px !important;
}

.tab-content .tab-content-mian {
    padding: 0px 0px 0px 40px !important;
}

.text h4 {
    color: #FFFFFF !important;
    font-size: 16px;
    line-height: 21px;
    margin: 0px;
}

.text h6 {
    color: #A4A4A4 !important;
    font-size: 16px;
    line-height: 21px;
    margin: 0px;
}

.teams-list {
    background-color: transparent !important;
    color: #FFFFFF !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 21px;
    margin: 0px;
    outline: none !important;
    border: none !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    cursor: pointer !important;
}

.dropdown_icon {
    background-color: transparent !important;
    color: #FFFFFF !important;
    font-size: 13px;
    border: none !important;
    border-radius: none !important;
    box-shadow: none !important;
}


.dropdown-list {
    background: #232323 0% 0% no-repeat padding-box !important;
    border: 1px solid #F26826 !important;
    border-radius: 8px !important;
    padding: 10px 15px !important;
    display: flex;
    flex-direction: column;
    gap: 10px !important;
    display: none;
    margin-left: 45px !important;
    margin-top: -5px !important;
}



.table_section {
    border: 1px solid #707070;
    border-radius: 8px;
    width: 100%;
}

.thead {
    background: #4A4A4A;
}

.table_section .table thead tr th {
    letter-spacing: 0px;
    color: #FFFFFF;
    font-size: 16px;
    padding: 15px 40px 12px 40px;
    border: none !important;
    font-weight: normal;
    border: none !important;
}

.table_section thead tr th:first-child {
    border-top-left-radius: 8px;
}

.table_section thead tr th:last-child {
    border-top-right-radius: 8px;
}

.table_section .table tbody {
    padding: 20px 0px;
}

.table_section .table tbody tr th,
.table_section .table tbody tr td {
    padding: 5px 40px;
    border: none !important;
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    font-weight: normal;
    border-top: none !important;
}

.table_section .table tbody tr th svg,
.table_section .table tbody tr td svg {
    margin-right: 5px;
}

.table_section .table tbody tr th img {
    border-radius: 100%;
    margin-right: 10px;
}

.table_section .table tbody tr:first-child th,
.table_section .table tbody tr:first-child td {
    padding-top: 35px;
}

.table_section .table tbody tr:last-child th,
.table_section .table tbody tr:last-child td {
    padding-bottom: 40px;
}

.categories {
    margin-bottom: 20px;
}

.categories .categories_box .categories_button {
    background: transparent;
    border: 1px solid;
    border: 1px solid #686868;
    border-radius: 4px;
    opacity: 1;
    outline: none !important;
    box-shadow: none !important;
    font-size: 12px;
    line-height: 11px;
    font-weight: normal;
    color: #fff;
    padding: 12px 20px;
}

.categories .categories_box .categories_button:hover {
    background: transparent;
    border: 1px solid;
    border: 1px solid #686868;
    border-radius: 4px;
    opacity: 1;
    outline: none !important;
    box-shadow: none !important;
    font-size: 12px;
    line-height: 11px;
    font-weight: normal;
    color: #fff;
    padding: 12px 20px;
}

.categories .categories_box .dropdown-menu-list {
    background: #232323;
    border-radius: 4px;
    opacity: 1;
}

.categories .categories_box .dropdown-menu-list .dropdown-menu-btn {
    background: transparent;
    outline: none !important;
    box-shadow: none !important;
    font-size: 12px;
    line-height: 11px;
    font-weight: normal;
    color: #fff;
    padding: 8px 20px;
}

.categories .categories_box .dropdown-menu-list .dropdown-menu-btn:active {
    color: #F26826;
}

.table_section .table tbody tr td .Result {
    border: 2px solid #fff;
    padding: 3px 30px;
    background: #33582D;
}

.table_section .table tbody tr td .Result img {
    margin-right: 10px;
}

.table_section .table tbody tr td .Result span {
    font-size: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.table_section .table tbody tr td .more_info {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    padding: 0px 30px;
    background: transparent;
}

.table_section .table tbody tr td .more_info .dropdown-toggle::after {
    display: none;
}

.table_section .table tbody tr td .more_info .dropdown-toggle::after {
    display: none;
}

.table_section .table tbody tr td .more_info button {
    background: transparent;
    outline: none !important;
    box-shadow: none !important;
    font-size: 12px;
    line-height: 11px;
    font-weight: normal;
    color: #F26826;
    border: none !important;
}

.table_section .table tbody tr td .more_info button:active {
    background: transparent;
    outline: none !important;
    box-shadow: none !important;
    font-size: 12px;
    line-height: 11px;
    font-weight: normal;
    color: #F26826;
    border: none !important;
}

.table_section .table tbody tr td .more_info .dropdown-menu-info {
    width: 220px;
    background: #232323;
    border: 1px solid #F26826;
    border-radius: 8px;
    padding: 10px 15px;

}

.table_section .table tbody tr td .more_info .dropdown-menu-info li {
    font-size: 10px;
    line-height: 20px;
    color: #DFDFDF;
}

.table_section .table tbody tr td .more_info .dropdown-menu-info li span {
    margin-left: 5px;
    font-size: 8px;
    line-height: 20px;
    color: #A1A1A1;
}


@media screen and (max-width: 1024px) {
    .popup-dialog {
        width: 90%;
        padding: 30px;
    }
}

@media screen and (max-width: 992px) {
    .popup-main {
        gap: 5px !important;
    }

    .popup-img img {
        width: 20% !important;
    }

    .popup-title {
        font-size: 18px !important;
    }

    .popup-subtitle {
        font-size: 18px !important;
    }

    .popup-sub-text .popup-title {
        font-size: 15px !important;
    }

    .popup-sub-text .popup-subtitle {
        font-size: 16px !important;
    }

    .nav-tabs {
        gap: 5px !important;
    }
}

@media screen and (max-width: 768px) {
    .nav-tabs .active a {
        padding: 10px 15px !important;
        font-size: 15px !important;
        line-height: 16px !important;

    }

    .nav-tabs li a {
        padding: 10px 15px !important;
        font-size: 15px !important;
        line-height: 16px !important;
    }

    .tab-content {
        padding: 40px 0px 0px 0px !important;
    }

    .tab-content .tab-content-mian {
        padding: 0px 0px 0px 0px !important;
    }

}

@media screen and (max-width: 700px) {
    .table_section .table thead tr th {
        padding: 15px 25px 12px 25px !important;
    }

    .table_section .table tbody tr th,
    .table_section .table tbody tr td {
        padding: 5px 30px !important;
    }

    .table_section .table tbody tr:first-child th,
    .table_section .table tbody tr:first-child td {
        padding-top: 25px !important;
    }
}

@media screen and (max-width: 500px) {
    .tab-content {
        padding: 40px 0px 0px 0px !important;
    }

    .nav-tabs li a {
        padding: 10px 10px !important;

    }

    .nav-tabs {
        gap: 0px !important;
    }

    .table_section .table tbody tr th,
    .table_section .table tbody tr td {
        padding: 5px 5px 5px 10px !important;
    }

    .table_section .table thead tr th {
        padding: 15px 5px 12px 10px !important;
    }

    .table_section .table tbody tr td .Result {
        padding: 8px 10px !important;
    }

    .table_section .table tbody tr td .more_info {
        padding: 0px 10px !important;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#info{
    padding: 15px 20px 30px 54px;
    border: 1px solid #707070;
    border-radius: 8px;
}

#info, #match, #transaction {
    animation: fadeIn 1s;
}

.ok-button-wrapper{
    width: 150px;
}

.category-dropdown-wrapper{
    margin-bottom: 10px;
}

.category-dropdown{
    padding: 10px;
    background-color: #232323;
    color: #DFDFDF;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid grey;
}

.table_section.matches-list table tbody tr{
    background-color: #F26826!;
}