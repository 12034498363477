.resetPwd-main {
  margin-top: 10%;
  /* font-family: "Roboto", sans-serif; */
}

.resetPwd-center {
  display: flex;
  justify-content: center;
  padding-top: 6%;
}

.resetPwd-zeroMargin {
  margin: 0px;
}

.resetPwd-frt-head {
  text-align: center;
}

.resetPwd-frt-head h4 {
  font: normal normal bold 18px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.resetPwd-frt-head p {
  margin-top: 4em;
  margin-bottom: 4em;
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.resetPwd-frm {
  width: 70%;
}

.resetPwd-mb-3 {
  border-bottom: solid 2px white;
}
.resetPwd-inputField {
  background: transparent;
  border: none;
  color: #ffffff;
  width: 80%;
  margin-left: 10px;
}

.resetPwd-sbtn {
  border: none;
  width: 100%;
  background-color: #f26826;
  color: white;
  font: normal normal normal 16px/30px Roboto;
  letter-spacing: 0resetpwd-16px;
  opacity: 1;
  border-radius: 4px;
}

.resetPwd-mt-4 {
  margin-top: 2rem !important;
}
.resetPwd-mt-2 {
  margin-top: 1rem !important;
}

.resetPwd-crt span {
  font: normal normal 300 14px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0resetpwd-7;
}

.resetPwd-crt-bt {
  background-color: transparent;
  font: normal normal 300 14px/30px Roboto;
  letter-spacing: 0px;
  cursor: pointer;
  color: #ffd4a0 !important;
  opacity: 1;
}
.resetPwd-crt-bt:hover {
  color: #ffd4a0;
}

.resetPwd-inputField:focus {
  outline: none;
  background: none;
}

@media only screen and (max-device-width: 500px) {
  .resetPwd-col-md-8 {
    display: none;
  }

  .resetPwd-top {
    align-items: flex-start;
  }
}
