*,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  border-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

#root {
  background-color: #1a1a1a !important;
}

a {
  text-decoration: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: #1a1a1a;
  -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
  -webkit-text-fill-color: white !important;
}

.Mui-checked,
.MuiCheckbox-indeterminate {
  color: #f26826 !important;
}

.MuiInput-root {
  color: white !important;
  text-align: left !important;
}

.MuiInputLabel-root {
  color: #535353 !important;
}

.MuiInput-root:before {
  border-color: white !important;
}

.MuiInputAdornment-root .MuiIconButton-root {
  color: white !important;
}

.far-apart-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

:root {
  --primary: #f1f1f1;
  --secondary: #b7b5b5;
  --tertiary: #717171;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 12px;
  border: 2px solid var(--primary);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--tertiary);
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}