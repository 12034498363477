.title {
  padding: 0% 6%;
  font-size: 20px;
  background: linear-gradient(
    90deg,
    #f26826 20%,
    rgba(0, 213, 255, 0) 97%,
    rgba(0, 0, 0, 0.12) 100%
  );
  position: absolute;
  left: 0;
  min-width: 100%;
}
