.franchiseCard {
  background: #2e2d2d 0 0 no-repeat padding-box !important;
  border-radius: 8px !important;
  padding: 16px;
  text-align: center;
}
.franchiseCard .cardBody {
  padding-top: 5px;
}
.franchiseName {
  height: 28px;
  width: 60%;
  background: linear-gradient(
    90deg,
    rgba(242, 104, 38, 1) 39%,
    rgba(0, 212, 255, 0) 97%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  bottom: 10%;
  left: 1%;
  font-style: bold;
  color: white;
}
.owner {
  color: #707070;
  font-size: 12px;
}
.ownerName {
  color: whitesmoke;
}
.profile {
  position: relative;
}
.cardBody span {
  font-size: 12px;
}
.profilePic {
  width: 100%;
  border-radius: 8px;
}
.profile {
  position: relative;
}
.win {
  color: #4ed45c !important;
  font-size: 10px;
}
.loss {
  color: #ff2e2e !important;
  font-size: 10px;
}
.cardBodyHead {
  margin: 0px;
  font: normal normal medium 14px/18px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
}
