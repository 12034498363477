.flTopContainer {
  height: 200px;
  align-items: center;
  border-bottom: 1px solid #707070;
}
.flName {
  font-weight: bold;
}
.flImg {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  cursor: pointer;
}
.centerBar {
  padding-top: 20px;
  padding-bottom: 20px;
}
.tabType {
  padding: 20px;
  color: white;
  cursor: pointer;
}
.styledTab {
  cursor: pointer;
  padding: 20px;
  color: #f26826;
  border-bottom: 2px solid #f26826;
}

.tournamentType {
  padding: 20px;
  color: white;
  cursor: pointer;
}
.styledTournament {
  cursor: pointer;
  padding: 20px;
  color: #f26826;
  border-bottom: 2px solid #f26826;
}
