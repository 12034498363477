.leftRectangle {
  padding: 50px;
  height: 200px;
  border: 1px solid #707070;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
}
.centerInnerRows {
  justify-content: center;
}
.rightRectangle {
  height: 200px;
  border: 1px solid #707070;
  border-radius: 8px;
  padding: 0px 10px;
  word-break: break-all;
}
.franchAboutSec {
  overflow-y: scroll;
  height: 155px;
  color: whitesmoke;

  /* Hide scrollbar for IE, Edge add Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.franchAboutSec::-webkit-scrollbar {
  display: none;
}
