.wrapper {
  width: 90%;
  max-width: 600px;
  height: 60vh;
  border-radius: 8px;
  background: #1a1a1a;
  border: 1px solid #ffffff4d;
  padding: 8px 14px;
  margin: 20vh auto;
  color: #fff;
}

.closeBtn {
  text-align: right;
  margin-bottom: 18px;

}

.closeBtn span {
  cursor: pointer;
}

.scrollWrapper {
  height: 50vh;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollWrapper::-webkit-scrollbar {
  // display: none;
  // background-color: transparent;
}

// /* Hide scrollbar for IE, Edge and Firefox */
// .scrollWrapper {
//   -ms-overflow-style: none;
//   /* IE and Edge */
//   scrollbar-width: none;
//   /* Firefox */
// }

.inventoryContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-sizing: border-box;
}

.notFoundContainer {
  height: 80%;
  display: flex;
  align-items: center;
  padding: 50px;
}

.inventoryItem {
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-between;
  align-items: center;
}

.inventoryItem .imageWrapper {
  height: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 12px;
}

.inventoryItem .imageWrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.inventoryItem .title h4 {
  font-size: 1.1rem;
}