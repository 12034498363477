.teamprofile-containerWrapper {
  height: 100%;
  overflow: auto;
}

.teamprofile-pic-container {
  padding-bottom: 2%;
  display: flex;
  align-items: center;
}

.teamprofile-avatar {
  position: relative;
  padding-left: 30px;
}

.teamprofile-card-col {
  margin-bottom: 50px;
}

.teamprofile-teamprofilepic {
  position: relative;
  padding: 0px 20px;
}
.teamprofile-teamprofilepicIcon {
  position: absolute;
  top: 65%;
  left: 70%;
}
.teamprofile-profilecard {
  background-color: #2e2d2d !important;
}

.teamprofile-flex-grid-top {
  margin-bottom: 30px;
  height: 250px !important;
}

#teamprofile-OpenImgUpload {
  background: transparent;
  border: none;
  height: 30px;
  cursor: pointer;
}
.teamprofile-topbodytext {
  padding-bottom: 10px;
}
.teamprofile-topbody-buttons {
  float: right;
}

.teamprofile-Profiletitle {
  font: 22px/20px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.teamprofile-profileEditbtn {
  background: transparent;
  border: none;
}

.card-col .teamprofile-card-body {
  padding: 15px 0px 10px 15px;
}

.teamprofile-medcardtext {
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
}

.teamprofile-bottom {
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
  color: #f26826;
}

.teamprofile-backgroundchange {
  position: absolute;
  left: 93%;
  top: 10%;
}

.teamprofile-flex-grid-bottom {
  display: flex;
  height: 70%;
}

.teamprofile-flex-grid-bottom .col {
  display: flex;
}

.teamprofileflex-grid-bottomChild {
  display: flex;
}

.teamprofile-col-md-8Inner {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 8px;
  padding: 0px 15px;
}

.teamprofilecol-md-8Inner::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.col-md-8 {
  display: flex;
  flex-direction: column;
}

.col-md-8 span {
  padding-bottom: 10px;
  font: normal normal normal 14px Roboto;
  color: #ffffff;
}

.teamprofile-currentMatchHeading {
  font: normal normal normal 14px/23px Roboto;
  letter-spacing: 0px;
  color: #767676;
  margin-top: 10px;
}

.teamprofile-colAbout {
  display: flex;
  flex-direction: column;
  padding: 0px;
  padding-left: 20px;
}

.teamprofile-colAbout span {
  font: normal normal normal 14px Roboto;
  color: #ffffff;
  padding-bottom: 10px;
}

.teamprofile-colAboutCTN {
  width: 100%;
  height: 100%;
  color: white;
  overflow: auto;
  overflow-wrap: break-word;
}

.teamprofile-colAboutCTN::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
}

.teamprofile-gamer-tag {
  width: 100%;
  height: 100%;
  border: 1px solid #707070;
  border-radius: 8px;
  padding: 10px;
  color: white;
  overflow: auto;
  overflow-wrap: break-word;
}
.teamprofile-gamecard {
  display: flex;
  background: #2e2d2d 0 0 no-repeat padding-box !important;
  border-radius: 8px;
  opacity: 1;
  height: 224px;
}
.teamprofile-gamecard .teamprofile-card-body {
  padding: 10px;
  padding-top: 5px;
}

.teamprofile-gamecard-profilePic {
  padding-left: 10px;
  padding-top: 10px;
  height: 150px;
}

.teamprofile-gamecard-profile {
  position: relative;
}

.teamprofile-gamecard-profiletext {
  position: absolute;
  color: white;
  bottom: 0px;
  left: 16px;
}

.teamprofile-card-bodyhead {
  margin: 0px;
  font: normal normal medium 14px/18px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
}

.teamprofile-pool {
  width: 33%;
  float: left;
  height: auto;
  font: normal normal normal 10px/18px Roboto;
  letter-spacing: 0px;
  color: #fff7f7;
}

.teamprofile-pool p {
  font: normal normal normal 10px/18px Roboto;
  letter-spacing: 0px;
  color: #767676;
  opacity: 1;
}

.teamprofile-pool span {
  font: normal normal medium 10px/18px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.teamprofile-teamsize {
  width: 33%;
  float: left;
  height: auto;
  font: normal normal normal 10px/18px Roboto;
  letter-spacing: 0px;
  color: #f7f7f7;
}

.teamprofile-teamsize p {
  font: normal normal normal 10px/18px Roboto;
  letter-spacing: 0px;
  color: #767676;
  opacity: 1;
}

.teamprofile-teamsize span {
  font: normal normal medium 10px/18px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.teamprofile-EntryFee {
  width: 33%;
  float: left;
  height: auto;
  font: normal normal normal 10px/18px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
}

.teamprofile-EntryFee p {
  font: normal normal normal 10px/18px Roboto;
  letter-spacing: 0px;
  color: #767676;
  opacity: 1;
}

.teamprofile-EntryFee span {
  font: normal normal medium 10px/18px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.teamprofile-gamecardScore {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 12px;
  padding-left: 40px;
  flex-wrap: wrap;
}

.teamprofile-row {
  display: flex;
  align-items: center;
}

.teamprofile-labels_tags {
  font-weight: bolder;
}

.teamprofile-gamer_tags {
  font-weight: normal;
}

.teamprofile-gamecardScore-row .col-md-4 h4 {
  font: normal normal medium 14px/23px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
}

.teamprofile-gamecardScore-row .col-md-4 h3 {
  font: normal normal normal 14px/23px Roboto;
  letter-spacing: 0px;
  color: #767676;
}

.teamprofile-form-group label {
  color: white;
  margin-bottom: 0;
  justify-content: center;
  display: flex;
  font-size: 18px;
}

#teamprofile-teamName {
  margin: 12px 0px 0px 0px;
}

.teamprofile-modal-body .inputField {
  width: 100%;
  border: 0;
  border-bottom: 2px solid lightgrey;
  color: #e4e4e4cf;
  margin-bottom: 5px;
  color: white;
}

.teamprofile-modal-footer {
  border: none;
}

.teamprofile-inputField:focus {
  outline: none;
}

.teamprofile-Modalbtn {
  background-color: #f26826;
  color: white;
  border: none;
  width: 70px;
  border-radius: 3px;
}

@media (max-width: 578px) {
  .teamprofile-flex-grid-bottom {
    display: block;
  }

  .teamprofile-background {
    display: grid;
    justify-content: center;
  }

  .teamprofile-avatar {
    padding: 0px;
    padding-top: 10px;
  }

  .teamprofile-flex-grid-top .teamprofile-col {
    display: flex;
    justify-content: center;
  }

  .teamprofile-card-col {
    padding-bottom: 20px;
    margin-top: 10px;
    width: 17rem;
  }

  .teamprofile-flex-grid-bottom .teamprofile-col {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 10px 0;
  }

  .teamprofile-flex-grid-bottom {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .teamprofile-gamecard {
    height: auto;
  }

  .teamprofile-backgroundchange {
    left: 85%;
  }

  .teamprofile-gamecardScore-row .teamprofile-col-md-4 {
    width: 50%;
  }

  .teamprofile-colAbout {
    padding: 0px;
  }

  .teamprofile-colAboutCTN {
    margin-bottom: 30px;
  }

  .teamprofile-teamprofilepic {
    width: 100px;
    height: 100px;
  }

  .teamprofile-teamprofilepicIcon {
    position: absolute;
    top: 72%;
    left: 55%;
  }
}

.teamprofile-no-padding {
  padding: 0px;
  margin: 0px;
}

@media only screen and (max-width: 1200px) {
  .teamprofile-gamecardScore-row .teamprofile-col-md-4 {
    width: 50%;
  }
}
