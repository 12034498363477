.dashContainer {
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.infoBox {
  height: 106px;
  width: 106px;
  border: 1px solid #707070;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff7700;
  font-size: 24px;
}
