.topContainer {
  border-radius: 8px;
  background-color: #232323;
  justify-content: center;
  align-items: center;
  width: 290px;
  height: 290px;
  padding: 30px 15px;
}
.circularImageRoot {
  border-radius: 50%;
  margin-bottom: "2px";
  height: 165px;
  width: 165px;
  margin: auto;
}
.circularImgPreview {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.palyerName {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  padding: 0px 8px;
}

.inputGroup {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 235px;
  justify-content: space-between;
  border-bottom: 1px solid #878787;
  padding-bottom: 10px;
  margin-left: 13px;
}
.formGroup {
  margin-top: 30px;
}
