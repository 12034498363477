.tabType {
  padding: 20px;
  color: white;
  cursor: pointer;
}
.styledTab {
  cursor: pointer;
  padding: 20px;
  color: #f26826;
  border-bottom: 2px solid #f26826;
}
.tabsSection {
  width: 100%;
  margin: auto;
  margin-top: 90px;
}
