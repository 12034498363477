.teamRoot {
  margin: 10px;
  padding: 10px;
  width: 224px;
  background: #232323;
  border-radius: 8px;
}

.teamTileTitle {
  font-size: 14px;
  color: white;
}
.teamsIcon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.teamsIconsImgs {
  justify-content: space-between;
  padding-right: 40px;
}
