.container {
  display: flex;
  justify-content: flex-end;
}
.innerContainer {
  border-right: none;
  background: #232323;
  border-radius: 8px 0px 0px 8px;
  padding-top: 16px;
  padding: 0px;
  // border: 1px #f26826 solid;
  position: absolute;
  height: calc(90vh - 65px - 114px);
  overflow: auto;
}
.innerContainer {
  width: 78px;
  animation: chatOpening 0.2s forwards;
}
@keyframes chatOpening {
  0% {
    width: 78px;
  }
  100% {
    width: 320px;
  }
}
.content {
  position: relative;
  height: 100%;
}
.headerContainer {
  display: flex;
  align-items: center;
  padding: 5%;
  border-bottom: 1px solid #1a1a1a;
  height: 70px;
  color: whitesmoke;
}
.headIcon {
  display: flex;
  align-items: center;
  padding-left: 2%;
}

.msgPicOut {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
}
.msgPic {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}


//msgs
.chatContainer {
  height: 82%;
  padding-bottom: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.chatContainer::-webkit-scrollbar{
  display: none;
}
.msgRow {
  width: 100%;
  padding: 0% 5%;
  margin: 3% auto;
  display: flex;
  // align-items: center;
}
.msgBox {
  margin-left: 3%;
  max-width: 85%;
  font-size: 12px;
  margin-bottom: 0px;
  position: relative;
  margin-right: 55px;
}
.msgText {
  color: whitesmoke;
  display: block;
  word-wrap: break-word;
  margin: 0;
  padding: 3px 0px 0px 0px;
}
.msgUserName {
  // color: whitesmoke;
  font-size: 14px;
  display: block;
  word-wrap: break-word;
  display: inline-block;
}
.msgTime {
  color: #767676;
}
.msgPic {
  height: 40px;
  width: 40px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #0c0c0c;
  flex-shrink: 0;
  padding: 0px;
}

//footer
.usetableInput {
  color: white !important;
  width: 85%;
  margin-bottom: 3%;
  background: #1a1a1a;
  height: 45px;
  border-radius: 8px;
  padding: 4%;
}
.inputBaseInput {
  color: white !important;
}
.footer {
  position: absolute;
  bottom: 2%;
  width: 100%;
  text-align: center;
}

// online users

.onlineUsers{
  position: absolute;
  top: 0px;
  right: 0px;
  display: inline-block;
  z-index: 100000;
  overflow: hidden;
}

.onlineUsersToggle{
  display: inline-block;
  padding: 0px 12px;
  background-color: #F26826;
  border-radius: 20px;
  cursor: pointer;
}
.onlineUsersDrawer{
  background-color: #232323;
  border: .5px solid #F26826;
  border-radius: 8px 0px 0px 8px;
  margin-top: 20px;
  padding: 10px;
  max-height: 250px;
  min-height: 150px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  transition-property: left;
  transition-duration: .3s;
  transition-timing-function: ease-in;
}
.onlineUsersDrawer.open{
  left: 0px;
}
.onlineUsersDrawer.close{
  left: 80px;
}

.onlineUsersDrawer::-webkit-scrollbar{
  display: none;
}
.onlineUser{
  position: relative;
  cursor: pointer;
}
.imgWrapper{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #F26826;
  overflow: hidden;
}
.imgWrapper img{
  width: 100%;
  height: 100%;
  padding: 0px;
  border-radius: 50%;
}

.offline {
  background-color: #c18e59;
  position: absolute;
  border-radius: 24px;
  height: 11px;
  width: 11px;
  right: 2%;
  bottom: 2%;
}
.online {
  background-color: #77f226;
  position: absolute;
  border-radius: 24px;
  height: 11px;
  width: 11px;
  right: 2%;
  bottom: 2%;
}