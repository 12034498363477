.winlossButtons {
  padding: 7px;
  border-radius: 12%;
  color: white;
  margin-left: 3px !important;
  cursor: pointer;
}
.winlossButtons {
  padding: 7px;
  border-radius: 12%;
  color: white;
  margin-left: 3px !important;
  cursor: pointer;
}
.tblContainer {
  width: 100%;
  margin: auto;
  background-color: #1a1a1a;
  margin-top: 2%;
}
