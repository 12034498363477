.cardStyle {
  background-size: cover;
  background-position: center;
  height: 198px;
  border-radius: 8px;
  position: relative;
}
.title {
  transform: translate(-50%, -50%);
}
