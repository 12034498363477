.comp-input {
  background: transparent;
  border: none;
  border-bottom: white 1px solid;
  border-radius: 0px;
  color: white;
  width: 100% !important;
  padding: 7px 0px;
  word-wrap: break-word;
}
.comp-input:disabled {
  color: #707070;
}
.filedIcon {
  position: absolute;
  right: 12px;
  z-index: 3;
}
