.topContainer {
  border-radius: 8px;
  border: 1px solid #f26826;
  background-color: #232323;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 301px;
  padding-top: 27px;
}
.circularImageRoot {
  border-radius: 50%;
  margin-bottom: 20px !important;
  height: 130px;
  width: 130px;
  margin: 0px auto;
}
.circularImgPreview {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.linesContainer {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
}
.waiverDropdown {
  position: absolute;
  right: 0px;
}
.waiverDropOption {
  background: #373737;
  padding: 5px 20px;
  font-size: 8px;
  cursor: pointer;
}
