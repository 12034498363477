.usetableInput {
  color: white !important;
  background-color: #1a1a1a;
  height: 40px;
  padding: 10px;
  border-radius: 7px;
}
.inputBaseInput {
  color: white !important;
}
