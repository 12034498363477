.credits-input {
  background: transparent;
  border: none;
  border-radius: 0px;
  color: white;
  width: 70px !important;
  padding: 0px 0px;
  word-wrap: break-word;
  padding-left: 8px;
  font-size: 25px;
  color: #a1a1a1;
}
.credits-form {
  .input-group {
    justify-content: center !important;
  }
}
.credits-editicon {
  padding-top: 8px;
  width: 14px;
}
.error-message {
  color: red;
}
