.topSection {
  height: 260px;
  width: 100%;
  background: black;
  border-radius: 8px;
  position: relative;
  border-bottom: 2px solid #f26826;
}

.matchProfileImg {
  position: absolute;
  bottom: 2%;
  display: flex;
  align-items: center;
}

.backChevron {
  top: 2%;
  left: 2%;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
}

.matchStartingBar {
  padding-left: 4%;
  font-size: 30px;
  color: white;
  height: 90px;
  width: 60%;
  background: linear-gradient(90deg,
      rgba(19, 18, 18, 0.781) 39%,
      rgba(0, 212, 255, 0) 97%,
      rgba(0, 0, 0, 0) 100%);
  position: absolute;
  bottom: 10%;
  font-style: bold;
}

.centerBar {
  padding-top: 20px;
  padding-bottom: 20px;
}

.matchName {
  color: white;
  font-size: 30px !important;
  font-weight: bold;
}

.miniDetailsBar {
  padding: 15px;
  border: 1px solid #707070;
  border-radius: 8px;
}

.bottomBar {
  padding: 2% 3%;
  border: 1px solid #707070;
  border-radius: 8px;
  color: white;
}

.timetext {
  color: #f26826;
}

.winningBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prizeBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerBar {
  padding-top: 20px;
  padding-bottom: 20px;
}

.tabType {
  padding: 20px;
  color: white;
  cursor: pointer;
}

.styledTab {
  cursor: pointer;
  padding: 20px;
  color: #f26826;
  border-bottom: 2px solid #f26826;
}