//roosters
.leaguesRosterBar {
  padding: 30px;
  border: 1px solid #707070;
  border-radius: 8px;
  overflow-y: scroll;
  max-height: 70vh;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.leaguesRosterBar::-webkit-scrollbar {
  width: 3px;
  background-color: #1a1a1a;
  border-radius: 8px;
}
.leaguesRosterBar::-webkit-scrollbar-thumb {
  background: #4a4a4a;
  border-radius: 8px;
}
