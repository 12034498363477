.changePwd-main {
  /* margin-top: 20vh; */
}

.changePwd-center {
  display: flex;
  justify-content: center;
  padding-top: 6vh;
  width: 100%;
}

.changePwd-zeroMargin {
  margin: 0px;
}

.changePwd-frt-head {
  text-align: center;
}

.changePwd-frt-head h4 {
  font: normal normal bold 18px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.changePwd-frt-head p {
  margin-top: 4em;
  margin-bottom: 4em;
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.changePwd-frm {
  width: 90vw;
  padding: 30px;
  max-width: 400px;
  border-radius: 8px;
  border: 1px solid #fff;
}
.change-form {
  margin-top: 40px;
}

.changePwd-mb-3 {
  border-bottom: solid 2px white;
  margin-bottom: 1rem;
}
.changePwd-inputField {
  background: transparent;
  border: none;
  color: #ffffff;
  width: 80%;
  margin-left: 10px;
}

.changePwd-sbtn {
  border: none;
  width: 100%;
  background-color: #f26826;
  color: white;
  font: normal normal normal 16px/30px Roboto;
  letter-spacing: 0forgotpwd-16px;
  opacity: 1;
  border-radius: 4px;
}

.changePwd-mt-4 {
  margin-top: 2rem !important;
}
.changePwd-mt-2 {
  margin-top: 1rem !important;
}

.changePwd-crt span {
  font: normal normal 300 14px/30px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0forgotpwd-7;
}

.changePwd-crt-bt {
  font: normal normal 300 14px/30px Roboto;
  letter-spacing: 0px;
  cursor: pointer;
  color: #ffd4a0 !important;
  opacity: 1;
}
.changePwd-crt-bt:hover {
  color: #ffd4a0;
}

.changePwd-inputField:focus {
  outline: none;
  background: none;
}
