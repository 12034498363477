* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main {
  width: 100%;
  height: 100vh;
  /* background-image:  url("/assets/images/home-background-image.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;
  background-position: center;
}

.content {
  width: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  padding-left: 540px;
  margin-top: 20px;

}

.content h1 {
  font-family: Bebas Neue, Regular;
  letter-spacing: 0px;
  font-size: 40px;
  padding: 0px;
  color: #ff5200;
  opacity: 1;
}

.content h2 {
  font-family: Bebas Neue, Regular;
  font-size: 40px;
  letter-spacing: 0px;
  padding: 0px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.win-to-page {
  background-color: #232323;
  padding: 100px 0px;
}

.win-to-content {
  padding: 0px 150px 60px 150px;
}

.win-to-content img {
  display: inline-block;
  width: 31px;
  height: 31px;
  opacity: 1;
}

.win-to-content h3 {
  display: inline-block;
  font-family: Roboto;
  font-size: 24px;
  letter-spacing: 0px;
  margin-top: -10px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.win-to-content p {
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0px;
  padding: 10px 0px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.5;
}

.win-to-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
}

.box-tournament {
  width: 332px;
  height: 295px;
  background-color: #000000;
  border: 1px solid #f26826;
  border-radius: 16px;
  opacity: 1;
}

.tournament-content {
  margin: 33px 39px;
}

.box-tournament img {
  width: 36px;
  height: 36px;
  opacity: 1;
}

.box-tournament h6 {
  padding-top: 24px;
  letter-spacing: 0px;
  color: #f26826;
  font-family: Roboto;
  font-size: 16px;
  opacity: 1;
}

.box-tournament p {
  padding-top: 24px;
  letter-spacing: 0px;
  color: #d6d6d6;
  text-align: left;
  font-family: Roboto;
  font-size: 12px;
  opacity: 1;
}

.tournament-button {
  text-align: center;
}

.tournament-button button {
  width: 111px;
  height: 34px;
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #d6d6d6;
  font-family: Roboto;
  font-size: 14px;
  border-radius: 2px;
  opacity: 1;
}

.box-playanyone {
  width: 332px;
  height: 295px;
  background-color: #000000;
  border: 1px solid #0060aa;
  border-radius: 16px;
  opacity: 1;
}

.playanyone-content {
  margin: 40px 41px;
}

.box-playanyone img {
  width: 38px;
  height: 38px;
  opacity: 1;
}

.box-playanyone h6 {
  padding-top: 22px;
  letter-spacing: 0px;
  color: #0060aa;
  font-family: Roboto;
  font-size: 16px;
  opacity: 1;
}

.box-playanyone p {
  padding-top: 24px;
  letter-spacing: 0px;
  color: #d6d6d6;
  text-align: left;
  font-family: Roboto;
  font-size: 12px;
  opacity: 1;
}

.playanyone-button {
  text-align: center;
}

.playanyone-button button {
  width: 111px;
  height: 34px;
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #d6d6d6;
  font-family: Roboto;
  font-size: 14px;
  border-radius: 2px;
  opacity: 1;
}

.box-challenge {
  width: 332px;
  height: 295px;
  background-color: #000000;
  border: 1px solid #9e8e00;
  border-radius: 16px;
  opacity: 1;
}

.challenge-content {
  margin: 20px 41px;
}

.box-challenge img {
  width: 83px;
  height: 83px;
  opacity: 1;
}

.box-challenge h6 {
  padding-top: 0px;
  letter-spacing: 0px;
  color: #9e8e00;
  font-family: Roboto;
  font-size: 16px;
  opacity: 1;
}

.box-challenge p {
  padding: 22px 0px;
  letter-spacing: 0px;
  color: #d6d6d6;
  text-align: left;
  font-family: Roboto;
  font-size: 12px;
  opacity: 1;
}

.challenge-button {
  text-align: center;
}

.challenge-button button {
  width: 111px;
  height: 34px;
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #d6d6d6;
  font-family: Roboto;
  font-size: 14px;
  border-radius: 2px;
  opacity: 1;
}

.follow-page {
  width: 100%;
  height: 98px;
  background-color: #000;
}

.follow-box {
  width: 100%;
  height: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
}

.follow-content h2 {
  font-family: Bebas Neue;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0px;
  opacity: 1;
}

.follow-button button {
  width: 143px;
  height: 42px;
  background-color: #f26826;
  color: #d6d6d6;
  font-family: Roboto;
  font-size: 18px;
  border-radius: 4px;
  opacity: 1;
  cursor: pointer;
}

.join-section {
  width: 100%;
  height: 242px;
  /* background-image:  url("../images/back17496@2x.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;
  border: 1px solid #707070;
  background-position: center;
}

.join-section img {
  float: left;
  margin-top: -50px;
  height: 242px;
  border-radius: 8px;
}

.join-box {
  float: right;
  width: 70%;
  height: 242px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
  text-align: right;
}

@media only screen and (max-width: 2000px) {
  .join-box {
    margin-top: -180px;
  }
}

.join-content span {
  font-family: Bebas Neue;
  font-size: 75px;
  color: #ffffff;
  letter-spacing: 0px;
  opacity: 1;
   
}

.join-button button {
  width: 143px;
  height: 42px;
  background-color: #f26826;
  color: #d6d6d6;
  font-family: Roboto;
  font-size: 18px;
  border-radius: 4px;
  opacity: 1;
  cursor: pointer;
}

.footer-distributed {
  width: 100%;
  height: 157px;
  background: #272727;
  opacity: 1;
  display: flex;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;
}

.footer-links a {
  text-decoration: none;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font: normal normal bold 30px/30px Bebas Neue;
  font-size: 22px;
}

.footer-distributed p {
  font: normal normal bold Bebas Neue;
  font-size: 10px;
  letter-spacing: 0px;
  color: #b1b1b1;
  opacity: 1;
}

.footer-right {
  display: flex;
  column-gap: 30px;
}

.footer-right a {
  text-decoration: none;
  color: white;
  opacity: 1;
  font: normal normal 18px/20px Bebas Neue;
  font-size: 20px;
}

.header-text {
  font-size: 24px;
  color: #ffffff;
  font-style: Bebas Neue, Bold;
  margin-top: 30px;
  margin-left: 25px;
}

.css-1480iag-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid #f26826;
}

.card-action-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: -55px;
  margin-top: 40px;
}

.message-field {
  margin-left: 30px;
  margin-top: 40px;
  width: 530px;
}

.send-button {
  width: 283px;
  height: 42px;
  background-color: #f26826;
  color: #ffffff;
  font: normal normal normal 16px/30px Roboto;
  letter-spacing: 0.16px;
  opacity: 1;
  cursor: pointer;
}

.send-btn {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 40px;
}

navlink.active {
  color: #f26826;
}

@media only screen and (max-width: 1600px) {
  .contact-cards {
    margin-right: 500px;
  }
}

.link-1 {
  font-family: 'Bebas Neue';
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  color: #f26826;
}

.link-1:hover {
  color: #f26826;
}