.matchTeamScroll {
  overflow-y: auto;
  // white-space: nowrap;
  // flex-wrap: nowrap;
}
.matchTeamScroll::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.matchTeamScroll {
  -ms-overflow-style: none;
  -o-overflow-style: none;
  scrollbar-width: none;
}
