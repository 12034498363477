  .user-navbar-container {
    position: fixed;
    top: 0;
    z-index: 20;
    width: 100%;
  }
  .container-fluid {
    margin: 0px 35px 0px 35px;
  }
  .nav-link.active {
    color: #ea8744 !important;
  }
  .navbar-custom {
    background: #1a1a1a 0% 0% no-repeat padding-box !important;
    opacity: 1 !important;
    border-bottom: 1px solid #f26826 !important;
    padding: 14px 140px;
  }
  @media (max-width: 1290px) {
    .navbar-custom {
      padding: 14px 50px;
    }
  }

  .navbar-custom .navbar-brand {
    cursor: pointer;
    font-family: "Bebas Neue";
    font-weight: bold;
    font-size: 30px;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 30px;
  }

  .header-nav {
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-weight: bold !important;
  }
  .navbar-custom .navbar-nav .nav-link {
    font: normal normal normal 14px/20px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    padding-left: 40px;
    border-top: none !important;
  }

  .navbar-custom .nav-item.active .nav-link,
  .navbar-custom .nav-item:hover .nav-link {
    color: #ffffff;
  }

  .form-control {
    background: #5857575c !important;
    border: none !important;
    border-radius: 24px !important;
    color: white !important;
  }

  .bt {
    margin-left: 20px;
  }

  .form-control:focus {
    outline: none;
  }

  .has-search {
    display: flex;
    margin-left: 20px;
    align-items: center;
    position: relative;
  }

  .has-search .form-control {
    padding-left: 2.375rem;
  }

  .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }
  .has-search .form-control-cross {
    pointer-events: all;
    right: 88px !important;
    cursor: pointer;
  }

  .bt {
    position: relative;
  }

  .dot {
    position: absolute;
    background-color: #ea8744;
    border-radius: 24px;
    height: 11px;
    width: 11px;
    left: 55%;
    top: 5%;
  }
  .active span {
    color: #ea8744;
  }
  .user-notif {
    padding-top: 2px;
  }
  .user-header-signout {
    padding-left: 15px;
    display: flex;
  }
  .parent-search {
    position: relative;
  }

  /*     search   */
  .header-box {
    cursor: default;
    position: absolute;
    width: 225px;
    min-height: 200px;
    background-color: #303030;
    border-radius: 8px;
    top: 40px;
    z-index: 5;
  }
  .header-box .header-box-scroll {
    max-height: 300px;
    overflow-y: scroll;
    scrollbar-width: 2px;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .header-box-scroll::-webkit-scrollbar {
    width: 3px;
    background-color: #1a1a1a;
  }
  /* Hide scrollbar for IE, Edge add Firefox */
  .header-box-scroll::-webkit-scrollbar-thumb {
    background: #4a4a4a;
    border-radius: 8px;
  }

  .leaderIcon {
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }
  .header-search {
    outline: none;
    box-shadow: none !important;
  }

  @media (max-width: 950px) {
    .form-control {
      float: left;
    }
  }
