.root {
  width: 224px;
  background: #232323;
  border-left: 3px solid #f26826;
  width: 100%;
  margin: 15px auto;
}
.tileTitle {
  font-size: 16px;
  padding: 20px 15px;
  color: whitesmoke;
}
.timeDate {
  font-size: 10px;
  padding: 10px 15px;
  color: #b9b9b9;
}
