.inviteScroll {
  overflow-y: scroll;
  max-height: 90vh;
  /* Hide scrollbar for IE, Edge add Firefox */
  //   -ms-overflow-style: none;
  scrollbar-width: 3px;
}
//chrome, safari and opera
.inviteScroll::-webkit-scrollbar {
  width: 3px;
  background-color: #1a1a1a;
}

.inviteScroll::-webkit-scrollbar-thumb {
  background: #4a4a4a;
  border-radius: 8px;
}

.roundTable {
  padding: 15px;
  border: 1px solid #707070;
  border-radius: 8px;
  text-align: center;
  width: 100%;
}

.roundTable .useTable_headRow__1PmYE{
  background-color: transparent !important;
}
