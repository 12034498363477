.teamRoot {
  margin: 1%;
  padding: 3%;
  width: 100%;
  border: 2px solid #232323;
  border-radius: 8px;
}
.teamTileTitle {
  font-size: 15px;
  font-weight: bold;
  color: white;
}
.datetime {
  color: #767676;
  font-size: 10px;
}
