.root {
  color: white !important;
  background-color: #1a1a1a;
  height: 40px;
  padding: 10px;
}
.inputBaseInput {
  color: white !important;
}
.headRow {
  border-bottom: 1px solid #767676;
  color: #f26826;
}
.headCell {
  color: #f26826 !important;
}
