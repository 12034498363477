// .userField {
//   display: flex;
//   justify-content: flex-start;
//   height: 28px;
//   border-bottom: 1px solid white;
// }

.form-group.userrow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
}

.inputgroup {
  display: flex;
  flex-direction: column;
}
