.rectangleImageRoot {
  width: 100%;
  height: 150px;
  position: relative;
  background: #1e1e1e;
  border: 1px solid #767676;
  border-radius: 10px;
}
.rectangleImguploadIcon {
  position: absolute;
  bottom: 8px;
  right: 5%;
}
.rectangleImgPreview {
  width: 100%;
  height: 100%;
  // border: 1px solid whitesmoke;
}
