.formWrapper{
    width: 80%;
    max-width: 400px;
    margin: auto;
    text-align: center;
    padding-top: 40px;

    .formTitle{
        display: flex;
        flex-direction: column;
        gap: 12px;
        color: #fff;

        .heading{
            font-size: 1.3rem;
            font-weight: bold;
        }
        .subHeading{
            color: #b4b4b4;
        }
    }
    .form{
        padding-top: 20px;
    }
}