.root {
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  width: 224px;
  background: #232323;
}
.imgLogo {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}
.tileTitle {
  font-size: 14px;
}
.content {
  font-size: 10px;
  color: #b4b4b4;
}
.value {
  font-size: 10px;
}
.teamIcon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
