.container {
  padding: 5px;
  padding-left: 0px;
  letter-spacing: 0px;
  color: #fff7f7;
  display: inline-block;
  box-sizing: border-box;
}
.title {
  margin: 0px;
  color: #767676;
  font-size: 12px;
}
.digitNo {
  font-size: 14px;
}
