.usetableInput {
  color: white !important;
  background-color: #1a1a1a;
  width: 300px;
  height: 40px;
  padding: 10px;
  border-radius: 7px;
}
.inputBaseInput {
  color: white !important;
}
.pagination {
  background-color: #4a4a4a;
  color: whitesmoke;
  padding-right: 40px !important;
  p {
    margin: 0 !important;
  }
  .MuiTablePagination-actions {
    margin-right: 20px !important;
  }
  button {
    background: #f26826 !important;
    padding: 3px 3px;
    margin: auto 7px;
  }
}
