.circularImageRoot {
  width: 100px;
  height: 100px;
  border: 1px solid #707070;
  border-radius: 50%;
  position: relative;
}
.circularImguploadIcon {
  position: absolute;
  left: 70%;
  bottom: 0px;
}
.circularImgPreview {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
