.wrapper {
    width: 100%;

    height: fit-content;
    border-radius: 8px;
    background: #1a1a1a;
    border: 1px solid #ffffff4d;
    padding: 20px 30px;
    color: #fff;

}


.scrollWrapper {
    height: 50vh;
    overflow-y: auto;
    padding: 10px;
}



.inventoryContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-sizing: border-box;
}

.notFoundContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.inventoryItem {
    display: flex;
    flex-direction: row;
    height: 100px;
    justify-content: space-between;
    align-items: center;
}

.inventoryItem .imageWrapper {
    height: 100%;
    overflow: hidden;
    border-radius: 12px;
}

.inventoryItem .imageWrapper img {
    height: 100px;
    width: 100px;
    object-fit: contain;
    background-color: #FFF;

}

.inventoryItem .title h4 {
    font-size: 1.1rem;
}