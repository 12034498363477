.title {
  color: whitesmoke;
  font-size: 24px;
  font-weight: bold;
}
.dontOwn {
  color: #767676;
}
.createFranchise {
  color: #f26826;
  cursor: pointer;
}
