.loader-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100vh !important; /* Adjust height based on your requirement */
  }
.loader-container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
    justify-content: center;
  }
  
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1) !important;
    border-top: 4px solid #fd7e14 !important; /* Change color or style as needed */
    border-radius: 50% !important;
    width: 50px !important;
    height: 50px !important;
    animation: spin 1s linear infinite;
    margin-bottom: 10px !important;
  }
  .loader1 {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #fff; /* Change color or style as needed */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }